<template>
  <div class="push-message">
    <PageTitle
      icon="chevron_left"
      title="使用券分群推播"
      hideBtn
      style="margin-bottom: 24px"
      @iconClick="$router.go(-1)"
    />

    <!-- 使用券 -->
    <section>
      <SectionTitle title="票券" hideBtn fontSize="18" />
      <div>
        <BaseElSelect
          v-model="couponId"
          popper-class="select-popper"
          placeholder="選擇票券"
          @visible-change="showCouponSelectModal = true"
        >
          <BaseElSelectOption
            :label="findCouponName(couponId)"
            :value="couponId"
          />
        </BaseElSelect>
      </div>
    </section>

    <!-- 搭配訊息 -->
    <section>
      <SectionTitle title="搭配訊息" hideBtn fontSize="18" />
      <!-- TODO Radio -->
      <BaseElRadioGroup v-model="useMessage" style="margin-bottom: 10px">
        <div class="radio-group">
          <BaseElRadio :label="true">是</BaseElRadio>
          <BaseElRadio :label="false">否</BaseElRadio>
        </div>
      </BaseElRadioGroup>
      <MessageBlock
        v-if="useMessage"
        class="mb-3"
        onlyText
        maxContent="500"
        :model.sync="message[0]"
        @error="onError = true"
        @checked="onError = false"
        @close="removeNotifyMessage(index)"
      />
    </section>

    <!-- 對象 -->
    <section>
      <SectionTitle title="對象" hideBtn fontSize="18" />
      <BaseElRadioGroup v-model="allMember" @change="tagChange">
        <div class="radio-group" @change="changeCondition">
          <BaseElRadio :label="true" class="flex">
            <p class="text" style="margin-bottom: 7px">所有會員</p>
            <div v-if="allMember" class="count flex flex-col" style="gap: 7px">
              <p>預計發送: {{ get(estimateCount,'member.count') }}人</p>
              <p class="flex items-center">
                完成Line綁定: {{ get(estimateCount, 'lineMember.count') }}人
                <el-tooltip placement="right">
                  <div slot="content" :style="`width: 200px`">
                    當您有開啟「票券發放通知」，完成Line綁定會員也會同時收到Line訊息推播通知。
                  </div>
                  <span class="material-icons text-gray-60">help_outline</span>
                </el-tooltip>
              </p>
            </div>
          </BaseElRadio>
          <BaseElRadio :label="false" class="flex">
            <p class="text" style="margin-bottom: 7px">進階標籤會員</p>
            <div v-if="!allMember" class="count flex flex-col" style="gap: 7px">
              <p v-if="get(estimateCount, 'member.isAccurate')">
                預計發送: {{ get(estimateCount, 'member.count') }}人
              </p>
              <p v-else>
                預計發送約{{ get(estimateCount, 'member.count') }}人 ({{ get(estimateCount, 'member.countConfidenceMin') }}~{{ get(estimateCount, 'member.countConfidenceMax') }}人)
              </p>
              <p class="flex items-center">
                <template v-if="get(estimateCount, 'lineMember.isAccurate')">
                  完成Line綁定: {{ get(estimateCount, 'lineMember.count') }}人
                </template>
                <template v-else>
                  完成Line綁定約{{ get(estimateCount, 'lineMember.count') }}人 ({{ get(estimateCount, 'lineMember.countConfidenceMin') }}~{{ get(estimateCount, 'lineMember.countConfidenceMax') }}人)
                </template>
                <el-tooltip placement="right">
                  <div slot="content" :style="`width: 200px`">
                    當您有開啟「票券發放通知」，完成Line綁定會員也會同時收到Line訊息推播通知。
                  </div>
                  <span class="material-icons text-gray-60">help_outline</span>
                </el-tooltip>
              </p>
            </div>
          </BaseElRadio>
        </div>
      </BaseElRadioGroup>

      <div v-if="!allMember" class="mt-3">
        <div
          v-for="(block, index) in conditions"
          :key="`block-${index}`"
          class="flex flex-col"
        >
          <div class="tag-search-container">
            <div class="flex flex-col gap-[10px]">
              <span>完全包含</span>
              <div class="flex items-center gap-[10px]">
                <TagSearch
                  :model.sync="block.includes"
                  :tags="tags"
                  objKey="id"
                  multiple
                  :preserve-null="true"
                  @update:model="tagChange"
                />
                <i
                  v-if="conditions.length > 1"
                  class="el-icon-circle-close close-btn"
                  @click="removeBlock(index)"
                />
              </div>
            </div>

            <div class="flex flex-col gap-[10px]">
              <span>排除</span>
              <div class="flex gap-[10px]" style="align-items: center;">
                <TagSearch
                  :model.sync="block.excludes"
                  :tags="tags"
                  objKey="id"
                  multiple
                  :preserve-null="true"
                  @update:model="tagChange"
                />
              </div>
            </div>
          </div>

          <div
            v-if="conditions.length > 1 && index < conditions.length - 1"
            class="m-2"
            style="color: #2BB8C1;"
          >
            or
          </div>
        </div>

        <AddButton @click="addCondition" />
      </div>
    </section>
    <!-- 時間 -->
    <section>
      <SectionTitle title="時間" hideBtn fontSize="18" />
      <BaseElRadioGroup v-model="immediate">
        <div class="radio-group">
          <BaseElRadio :label="true">立即發送</BaseElRadio>
          <BaseElRadio :label="false">排程單次推播</BaseElRadio>

          <div v-if="!immediate" class="flex gap-10">
            <el-date-picker
              v-model="dateTime.date"
              editable
              type="date"
              placeholder="選擇日期"
              @change="dateChange"
            />
            <el-time-select
              v-model="dateTime.time"
              :picker-options="{
                minTime,
                start: '00:00',
                step: '00:15',
                end: '24:00',
              }"
              placeholder="選擇時間"
            />
          </div>
        </div>
      </BaseElRadioGroup>
    </section>

    <PageFixedFooter
      confirmBtn="發送"
      :confirmLoading="sending"
      @cancel="$router.go(-1)"
      @confirm="sendMessage"
    />
    <WarningDialog
      v-if="showWarningDialog"
      content="未開啟 Line 訊息通知，確認發送？"
      @close="showWarningDialog = false"
      @confirm="showWarningDialog = false, sendMessage($event, true)"
    />

    <CouponAdvanceSelect
      v-if="showCouponSelectModal"
      :data="couponList"
      :typeOptions="availableCouponType"
      @confirm="onAddCoupon"
      @close="showCouponSelectModal = false"
    />
  </div>
</template>

<script>
import WarningDialog from '@/components/Dialog/WarningDialog.vue'
import {
  GetLinePushConfig,
} from '@/api/shop'
import { Estimate, CreatePushMessage } from '@/api/pushMessage'
import { CheckCouponLinePush, GetCoupon, GetCouponCount } from '@/api/lottery/coupon'
import TagSearch from '@/components/Search/TagSearch'
import MessageBlock from '@/components/Message/MessageBlock.vue'
import { mapGetters } from 'vuex'
import dayjs from '@/lib/dayjs'
import { isInvalidDate } from '@/utils/date'
import { computed, defineComponent, onMounted, ref, reactive } from 'vue'
import { useTagStore } from './components/useTagStore'
import CouponAdvanceSelect from '@/components/Select/CouponAdvanceSelect.vue'
import { couponTypeConfig } from '@/config/couponExchange'
import { getAllDataFromApi } from '@/utils/helper'
import { useRoute } from 'vue-router/composables'
import { useShop } from '@/use/shop'
import { find, filter, get, omit } from 'lodash'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'PushMessage',
  components: { TagSearch, MessageBlock, CouponAdvanceSelect, WarningDialog },
  setup () {
    const route = useRoute()
    const { shopId } = useShop()
    const { tags, getAllTags } = useTagStore()
    const { checkAction } = usePermissions()
    const tagQuery = computed(() => route.query.tagName)
    const tagMemberCount = ref(0)
    const allMember = ref(true)
    const loading = ref(false)
    const estimateCount = reactive({
      member: {
        count: 0,
      },
      lineMember: {
        count: 0,
      },
    })
    const conditions = ref([
      {
        includes: [],
        excludes: [],
      },
    ])
    const estimate = async () => {
      loading.value = true
      const basePayload = {
        shopId: shopId.value,
        conditions: conditions.value,
      }

      const res = await Promise.allSettled([
        Estimate({ ...basePayload, object: 'lineMember' }),
        Estimate({ ...basePayload, object: 'member' }),
      ])
      loading.value = false

      estimateCount.lineMember = get(res, '[0].value')
      estimateCount.member = get(res, '[1].value')
    }

    const availableCouponType = computed(() => {
      const omitList = []
      if (!checkAction('admin.coupon.page'))omitList.push('coupon')
      if (!checkAction('admin.couponExchange.page'))omitList.push('couponExchange')
      if (!checkAction('admin.couponOpentix.page'))omitList.push('couponOpentix')
      if (!checkAction('admin.couponPospal.find'))omitList.push('pospal')
      return omit(couponTypeConfig, omitList)
    })

    onMounted(async () => {
      await getAllTags()

      if (tagQuery.value) {
        const exist = find(tags.value, { name: tagQuery.value })
        if (exist) {
          allMember.value = false
          conditions.value[0].includes.push(exist.id)
          await estimate()
        }
      }
    })

    return {
      tags,
      loading,
      conditions,
      estimate,
      allMember,
      tagMemberCount,
      estimateCount,
      get,
      availableCouponType,
    }
  },
  data () {
    return {
      sending: false,
      notifyConfig: {},
      showWarningDialog: false,
      selectCoupon: null,
      useMessage: true,
      onError: false,
      content: '',
      immediate: true,
      // allMember: true,
      count: 0,
      // tagMemberCount: 0,

      dateTime: {
        date: new Date(),
        time: '00:00',
      },

      // conditions: [
      //   {
      //     includes: [],
      //     excludes: [],
      //   },
      // ],

      message: [
        {
          type: 'text',
          content: '',
          action: undefined,
          actionContent: undefined,
        },
      ],
      showCouponSelectModal: false,
      couponList: [],
      couponId: null,
    }
  },
  computed: {
    ...mapGetters(['shop']),
    expectSendAt () {
      const year = dayjs(this.dateTime.date).year()
      const month = dayjs(this.dateTime.date).month()
      const date = dayjs(this.dateTime.date).date()
      const time = this.dateTime.time.split(':')
      const dateTime = new Date(year, month, date, time[0], time[1])
      return isInvalidDate(dateTime) ? new Date() : dateTime
    },
    couponNotify () {
      return this.notifyConfig?.couponRecordOpen
    },
    minTime () {
      const isAfterDate = dayjs(this.dateTime.date).isAfter(dayjs())
      if (isAfterDate) return ''
      const now = dayjs().format('HH:mm')
      return now
    },
    couponTypeConfig () {
      return couponTypeConfig
    },
  },

  async mounted () {
    await this.checkCouponLinePush()
    await this.estimate()
    this.dateChange()
    this.getAllCoupon()
  },

  methods: {
    dateChange (date) {
      const isSameDate = dayjs(this.dateTime.date).isSame(dayjs(), 'date')
      let [hour, min] = this.minTime.split(':')

      if (min === '00') min = '00'
      else if (+min < 15) min = '15'
      else if (+min < 30) min = '30'
      else if (+min < 45) min = '45'
      else if (+min > 45) {
        if (hour !== '24') {
          hour = Number(hour) + 1
          min = '00'
        }
      }

      if (isSameDate) {
        const time = `${hour}:${min}`
        this.dateTime.time = time
      }
    },
    async getLinePushConfig () {
      try {
        const config = await GetLinePushConfig({ shopId: this.shop })
        this.notifyConfig = config
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    async checkCouponLinePush () {
      const [res, err] = await CheckCouponLinePush({ shopId: this.shop })
      if (err) {
        this.$message.error(err.message)
        return
      }
      if (res) this.notifyConfig = res
    },
    changeCondition () {
      this.conditions = [
        {
          includes: [],
          excludes: [],
        },
      ]
    },

    async tagChange () {
      await this.estimate()
    },

    addCondition () {
      this.conditions.push({
        includes: [],
        excludes: [],
      })
    },

    removeBlock (index) {
      this.conditions.splice(index, 1)
    },

    removeNotifyMessage (index) {
      this.message.splice(index, 1)
    },

    addNotifyMessage () {
      this.message.push({
        type: 'text',
        content: '',
        action: undefined,
        actionContent: undefined,
      })
    },

    //= > 創建推播
    async createPushMessage () {
      try {
        const res = await CreatePushMessage({
          shopId: this.shop,
          action: 'coupon',
          couponId: this.couponId,
          setting: this.useMessage ? {
            message: this.message[0].content,
          } : undefined,
          // messages: this.message,
          expectSendAt: this.immediate ? null : this.expectSendAt,
          conditions: this.conditions,
        })
        return res.id
      } catch (error) {
        console.log(error)
        this.$message.error(error)
        return false
      }
    },

    //= > 發送推播控制
    async sendMessage (e, force = false) {
      this.sending = true
      if (this.onError) {
        this.sending = false
        return
      }
      if (!this.couponId) {
        this.$message.warning('請選擇票券')
        this.sending = false
        return
      }
      if (
        this.useMessage &&
        this.message[0].content === '' &&
        this.message[0].actionContent === undefined
      ) {
        this.$message.warning('請輸入訊息內容')
        this.sending = false
        return
      }
      if (!force && !this.couponNotify) {
        this.showWarningDialog = true
        this.sending = false
        return
      }
      try {
        if (!await this.createPushMessage()) {
          this.sending = false
          return
        }
        this.resetMessage()
        this.$message.success('已發送推播訊息 !')
        this.$router.push({
          name: 'PushMessageSetting',
          query: {
            tab: 'coupon',
          },
        })
        this.sending = false
      } catch (error) {
        console.log(error)
        this.sending = false
        this.$message.error(error)
      }
    },

    resetMessage () {
      this.conditions = [
        {
          includes: [],
          excludes: [],
        },
      ]

      this.message = [
        {
          type: 'text',
          content: '',
          action: undefined,
          actionContent: undefined,
        },
      ]
    },

    onAddCoupon (coupon) {
      if (coupon.length > 0) {
        this.couponId = coupon[0]
      }
    },

    findCouponName  (idx) {
      const coupon = this.couponList.find(({ id }) => id === idx)
      if (coupon) return coupon.name
      return ''
    },
    async getCouponCount () {
      const [res, err] = await GetCouponCount({ shopId: this.shop, type: 'all' })
      if (err) throw err
      return res.count
    },
    async getAllCoupon  () {
      let max
      try {
        max = await this.getCouponCount()
      } catch (error) {
        this.$message.error(error)
        return
      }
      const config = {
        shopId: this.shop,
        start: 0,
        limit: 100,
        type: 'all',
      }
      const [res, err] = await getAllDataFromApi(
        max,
        GetCoupon,
        config,
        true,
      )
      if (err) return window.$message.error(err)
      this.couponList = res
    },
  },
})
</script>

<style scoped lang="scss">
::v-deep .el-radio__input {
  @apply py-[5px];
}
.push-message {
  @apply pb-[100px];
}
.title {
  font-size: 24px;
  margin-bottom: 10px;
  .hint {
    @apply text-primary-100 mr-[10px];
  }
}

.radio-group {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .text {
    @apply mr-[10px] leading-[24px];
  }
}

.tag-search-container {
  @apply flex flex-col;
  // align-items: flex-end;
  gap: 10px;
  margin: 10px 0;

  .close-btn {
    @apply cursor-pointer;
    font-size: 20px;
  }
}
</style>
